import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';

import {StripeProvider} from 'react-stripe-elements';
import {Stripe} from 'stripe.js'
import Checkout from '../../components/checkout';

import Kids from '../../pics/young-coders-after-school-classes.jpg'

const YoungCoders = () => (

	<div className="white grid-1-3"
	>
	<Helmet

	title="BCS Young Coders After-School Classes 2018-2019 payments page"
	meta={[
		{ name: 'description', content: 'BCS Payments page' },
		{ name: 'keywords', content: 'payment form' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]}
		script={[
			{ "src": "https://js.stripe.com/v3/" }
			]}
			/>
			<div>
			<h1>BCS Young Coders After-school classes 2018-2019</h1>
			<h2>Monthly payment</h2>
			</div>

			<div style={{
				marginTop: '2em',

			}}>
			<img 
			className='banners'
			src={Kids} 
			alt='BCS Young Coders After-School Classes 2018-2019'/>

			</div>



			

			<h2 id="cohorts">Access</h2>
			<div>
			<div className="event" >
			<h4>90€</h4>
			<span>
			<h2>Monthly payment</h2>
			</span>
			<span>
			
			<Link to="/register" state={{course: "BCS Young Coders After-School Classes 2018-2019", date:'According to schedule', cost:90}}>
			<button>Proceed to checkout</button>
			</Link>
			</span>
			</div>
			
		

			
			</div>




			</div>


			)

export default YoungCoders
